.App {
    text-align: center;
}

.App-logo {
    height: 30vmin;
    min-height: 128px;
    pointer-events: none;
}

.App-main {
    background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    background-image: url('./assets/background.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 4vh;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.glass-effect {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    width: 60vw;
    border: solid;
    border-width: 1px;
    border-color: dimgray;
}

.glass-header {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8vh;
    min-height: 60px;
    overflow: hidden;
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: dimgray;
}

.header-logo {
    height: auto;
    width: 10vw;
    min-width: 180px;
    position: absolute;
    left: 1vw;
    top: 50%;
    transform: translateY(-50%);
}

:root {
    --color-primary: #B5325B; /* Laika */
    --color-primary-light: #CF5F7D; /* Lighter Laika */
    --color-secondary: #469C9A; /* Darkened Astro */
    --color-secondary-light: #54C8CB; /* Astro */
    --color-tertiary: #66a82e; /* Cosmo */
    --color-tertiary-light:#7fb84a; /* Lighter Cosmo */
    --color-text-light: snow;
}

.App-link {
    background: var(--color-primary);
    color: var(--color-text-light);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: calc(10px + 2vmin);
    padding: 0.75em 2em;
    position: relative;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 2.5vh auto;
    width: 20vw;
    min-width: 20px;
    max-width: 160px;
    min-height: 20px;
    max-height: 20px;
    height: auto;
    text-decoration: none;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
}

.App-link:hover {
    animation: glow 1s ease-in-out infinite;
}

.App-link::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: var(--color-primary);
    border-radius: 25px;
    filter: blur(10px);
    opacity: 0.75;
    z-index: -1;
    transition: all 0.3s ease-in-out;
}

.App-link:hover::before {
    filter: blur(20px);
    opacity: 1;
}

@keyframes glow {
    0%, 100% {
        background-color: var(--color-primary);
    }
    50% {
        background-color: var(--color-primary-light);
    }
}

.App-footer {
    background-color: #222222;
    color: #707070;
    text-align: center;
    padding: 1vh 0;
    font-size: calc(8px + 1vmin);
    width: 100%;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
}

.footer-section {
    margin-bottom: 1vh;
}

.small-text {
    font-size: calc(6px + 1vmin);
    margin-left: 2vmin;
    margin-right: 2vmin;
}

.secondary-link {
    background: var(--color-secondary);
}

.secondary-link::before {
    background: var(--color-secondary);
}

.secondary-link:hover::before {
    background: var(--color-secondary);
}

.secondary-link:hover {
    animation: secondary-glow 1s ease-in-out infinite;
}

@keyframes secondary-glow {
    0%, 100% {
        background-color: var(--color-secondary);
    }
    50% {
        background-color: var(--color-secondary-light);
    }
}

.tertiary-link {
    background: var(--color-tertiary);
}

.tertiary-link::before {
    background: var(--color-tertiary);
}

.tertiary-link:hover::before {
    background: var(--color-tertiary);
}

.tertiary-link:hover {
    animation: tertiary-glow 1s ease-in-out infinite;
}

@keyframes tertiary-glow {
    0%, 100% {
        background-color: var(--color-tertiary);
    }
    50% {
        background-color: var(--color-tertiary-light);
    }
}
